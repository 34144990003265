/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect, useState} from 'react'
import {Link} from 'react-router-dom'
import {removeAuth, useAuth} from '../../../../app/modules/auth'
import Avatar from 'react-avatar'
import {toAbsoluteUrl} from '../../../helpers'
import {fetchProfile} from '../../../../app/modules/accounts/components/settings/_requests'
import {
  IProfileDetails,
  profileDetailsInitValues as initialValues,
} from '../../../../app/modules/accounts/components/settings/SettingsModel'

const HeaderUserMenu: FC = () => {
  const {currentUser, logout} = useAuth()
  const [data, setData] = useState<IProfileDetails>(initialValues)
  // console.log("cu",currentUser)
  let passid = currentUser?.userId
  if (!passid) {
    passid = currentUser?.userId
  }
  useEffect(() => {
    passid &&
      fetchProfile(passid).then((res: any) => {
        // console.log('res-----', res)
        setData(res.data.Data[0])
      })
  }, [passid])
  var MainName = currentUser?.name

  const handleSignOut = () => {
    //debugger;
    localStorage.setItem('cma_auth_detail', '')
    localStorage.removeItem('cma_auth_detail')
    //console.log("f",localStorage.getItem("cma_auth_detail"))
    localStorage.clear()
    removeAuth()
    logout()
  }

  return (
    <div
      className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px'
      data-kt-menu='true'
    >
      <div className='menu-item px-3'>
        <div className='menu-content d-flex align-items-center px-3'>
          <div className='symbol symbol-50px me-5'>
            <Avatar size='40' round='20px' maxInitials={2} name={data?.name}></Avatar>
          </div>

          <div className='d-flex flex-column'>
            <div className='fw-bolder d-flex align-items-center fs-5'>{data?.name}</div>
            <a
              href='#'
              className='fw-bold text-muted text-hover-primary fs-7'
              style={{maxWidth: '200px'}} // Adjust maxWidth as needed
              title={data?.email}
            >
              {data?.email}
            </a>
          </div>
        </div>
      </div>

      <div className='separator my-2'></div>

      <div className='menu-item px-5 my-1'>
        <Link to='/profile/list' className='menu-link px-5'>
          <i className='fas fa-user'></i>
          <span className='ms-3'> My Profile</span>
        </Link>
      </div>
      <div className='menu-item px-5 my-1'>
        <Link to='/changePassword/list' className='menu-link px-5'>
          <i className='fas fa-key'></i>
          <span className='ms-2'>Change Password</span>
        </Link>
      </div>
      <div className='menu-item px-5'>
        <a onClick={() => handleSignOut()} className='menu-link px-5'>
          <i className='fas fa-sign-out-alt'></i>
          <span className='ms-2'> Sign Out</span>
        </a>
      </div>
    </div>
  )
}

export {HeaderUserMenu}
