import React from 'react'
import clsx from 'clsx'
import "./dashboard.css"
type Props = {
  className: string
  title?: string
  subTitle?: string
  tasks: any
}

type CardsWidgetProps = {
  className: string
  requestCount: number
  pendingCount: number
  icon?: any
  description: string
  color: string
  img: string
}

const ListsWidget: React.FC<Props> = ({className, tasks, title, subTitle}) => {

  return (
    <div className={`card ${className}`}>         
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold text-dark'>{title}</span>
          <span className='text-muted mt-1 fw-semibold fs-7'>{subTitle}</span>
        </h3>
      </div>
      {/* end::Header */}

      {/* begin::Body */}
      <div className='card-body pt-5' style={{height: '250px', overflowY: 'auto'}}>
        {tasks.map((task: any, index: any) =>{
            const isIncrease = task?.status === 'increase'
            const isDecrease = task?.status === 'decrease'
            const arrowColor = isIncrease ? 'text-success' : isDecrease ? 'text-danger' : ''
            const arrowIcon = isIncrease ? '↑' : isDecrease ? '↓' : ''
            console.log("arrow ", arrowColor ,arrowIcon,task   );
          return(
          <div className='d-flex align-items-center mb-7' key={index}>
            {/* begin::Text */}
            <div className='d-flex flex-grow-1 justify-content-between align-items-center'>
              <span className='text-dark text-hover-primary fs-6 fw-bold flex-grow-1'>
                {task?.name}
              </span>
              <div className='text-dark text-hover-primary fs-6'>
              <span className={`mx-1 ${arrowColor} ${isIncrease ? 'rotate-down' :'rotate-up'}`}>{arrowIcon}</span>
              <span className='text-center'> {task?.userCount}</span>
               
                </div>
            </div>
            {/* end::Text */}
          </div>
        )})}
      </div>

      {/* end::Body */}
    </div>
  )
}

const ListsWidgetRequest: React.FC<Props> = ({className = '', tasks, title, subTitle}) => {
  return (
    <div className={`card ${className}`}>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold text-dark'>{title}</span>
          <span className='text-muted mt-1 fw-semibold fs-7'>{subTitle}</span>
        </h3>
      </div>
      {/* end::Header */}

      {/* begin::Body */}
      <div className='card-body pt-5' style={{height: '250px', overflowY: 'auto'}}>
        {tasks.map((task: any, index: any) => {
          const isIncrease = task?.status === 'increase'
          const isDecrease = task?.status === 'decrease'

          // Ensure percentage is a number, defaulting to 0 if it's not
          const percentageValue = task?.percentage
          const percentage =
            Number(percentageValue) === 0 ? 'Nil' : `${Number(percentageValue).toFixed(2)}%`

          const badgeClass = isIncrease
            ? 'badge-light-success'
            : isDecrease
            ? 'badge-light-danger'
            : 'badge-light-info'

          const arrowIcon = isIncrease ? '↑' : isDecrease ? '↓' : ''

          return (
            <div className='d-flex align-items-center mb-7' key={index}>
              {/* begin::Text */}
              <div className='d-flex flex-grow-1 justify-content-between align-items-center'>
                <div className='d-flex flex-column'>
                  <a href='#' className='text-dark text-hover-primary fs-6 fw-bold'>
                    {task.product}
                  </a>
                  <span className='text-muted fw-semibold'>{task.Count}</span>
                </div>
                <div className='d-flex align-items-center'>
                  <span
                    className='text-dark text-hover-primary fw-bold fw-800 fs-6'
                    style={{width: '50px', textAlign: 'center'}}
                  >
                    {task.requestCount}
                  </span>
                  <span
                    className={`flex badge ${badgeClass} rounded-pill p-1 d-flex align-items-center justify-content-center`}
                    style={{width: '80px', transition: 'background-color 0.3s'}}
                  >
                    <span className='mx-1 text-center'>{arrowIcon}</span>
                    <span className='text-center'>{percentage}</span>
                  </span>
                </div>
              </div>
              {/* end::Text */}
            </div>
          )
        })}
      </div>
      {/* end::Body */}
    </div>
  )
}

const CardsWidget: React.FC<CardsWidgetProps> = ({
  className,
  description,
  color,
  pendingCount,
  requestCount,
}) => {
  const pendingPercentage = requestCount > 0 ? ((pendingCount / requestCount) * 100).toFixed(2) : 0
  return (
    <div
      className={`card card-flush bgi-no-repeat bgi-size-contain bgi-position-x-end ${className}`}
      style={{
        backgroundColor: color,
        height: '160px', // Set overall height
      }}
    >
      <div className='card-header pt-5' style={{height: '60px'}}>
        {' '}
        {/* Adjusted header height */}
        <div className='card-title d-flex flex-column'>
          <div className='d-flex align-items-center'>
            <span className='fs-2hx fw-bold text-dark-400 me-2 lh-1 ls-n2'>{requestCount}</span>

            {/* <span className="badge badge-light-success fs-base">↑ 2.2%</span> */}
          </div>
          <span className='text-dark-300 pt-1 fw-semibold fs-6'>{description}</span>
        </div>
      </div>
      <div className='card-body d-flex align-items-end pt-0' style={{height: '80px'}}>
        {' '}
        {/* Adjusted body height */}
        <div className='d-flex align-items-center flex-column mt-3 w-100'>
          <div className='d-flex justify-content-between fw-bold fs-6 text-dark opacity-75 w-100 mt-auto mb-2'>
            <span>{pendingCount} Pending</span>
            <span>{pendingPercentage}%</span>
          </div>

          <div className='h-8px mx-3 w-100 bg-white bg-opacity-50 rounded'>
            <div
              className='bg-success rounded h-8px'
              role='progressbar'
              style={{ width: `${Number(pendingPercentage) == 0 ? 3 : Number(pendingPercentage)}%` }}
              aria-valuenow={Number(pendingPercentage)}
              aria-valuemin={0}
              aria-valuemax={100}
            ></div>
          </div>
        </div>
      </div>
    </div>
  )
}

const states = ['warning', 'info', 'primary', 'danger'] // Define available states

const generateUserInitialsWithStates = (users: any) => {
  if (!Array.isArray(users)) return [] // Ensure users is an array, return empty array if not
  return users.map((user) => {
    const initials = user?.initials.charAt(0).toUpperCase() // Get the first letter of the name and capitalize it
    const state = states[Math.floor(Math.random() * states.length)] // Randomly assign a state
    const id = user?.id // Randomly assign a state
    const name = user?.initials // Randomly assign a state
    return {initials, state, id, name}
  })
}

interface UserProps {
  className: string
  description: string
  labelColor: string
  textColor: string
  users?: any // Define users as an optional prop of an array of objects
}

const CardsWidgetUser: React.FC<UserProps> = ({
  className,
  description,
  labelColor,
  textColor,
  users = [],
}) => {
  // Generate dynamic content based on the users passed
  const items = generateUserInitialsWithStates(users.todayUsers)

  return (
    <div
      className={`card card-flush mb-3 ${className}`}
      style={{height: '160px'}} // Set overall height
    >
      <div className='card-header pt-5' style={{height: '60px'}}>
        {' '}
        {/* Adjust header height */}
        <div className='card-title d-flex flex-column'>
          <span className='fs-2hx fw-bold text-gray-900 me-2 lh-1 ls-n2'>
            {users.userCurrentMonthCount}
          </span>
          <span className='text-gray-500 pt-1 fw-semibold fs-6'>{description}</span>
        </div>
      </div>

      <div
        className='card-body d-flex flex-column justify-content-end pe-0'
        style={{height: '80px'}}
      >
        {' '}
        {/* Adjust body height */}
        <span className='fs-6 fw-bolder text-dark-800 d-block mb-2'>
          {' '}
          Today’s {items.length === 1 ? 'Hero' : 'Heroes'}
        </span>
        <div className='symbol-group symbol-hover flex-nowrap'>
          {items.length > 0 ? (
            items.map((item, index) => (
              <div
                className='symbol symbol-35px symbol-circle'
                data-bs-toggle='tooltip'
                title={item.name}
                key={`cw7-item-${index}`}
              >
                {item.state && item.initials && (
                  <a href={`/users/view/${item.id}`}>
                    <span
                      className={clsx(
                        'symbol-label fw-bold',
                        'bg-' + item.state,
                        'text-inverse-' + item.state
                      )}
                    >
                      {item.initials}
                    </span>
                  </a>
                )}
              </div>
            ))
          ) : (
            <span>Today No one Joined</span>
          )}
          {users.todayUsersCount > 6 && (
            <a href='/users/list' className='symbol symbol-35px symbol-circle'>
              <span
                className={clsx(
                  'symbol-label fs-8 fw-bold',
                  'bg-' + labelColor,
                  'text-' + textColor
                )}
              >
                +{users.todayUsersCount - 6}
              </span>
            </a>
          )}
        </div>
      </div>
    </div>
  )
}

export {ListsWidget, ListsWidgetRequest, CardsWidget, CardsWidgetUser}
