// @ts-nocheck
import {Column} from 'react-table'
import {EmployeeInfoCell} from './EmployeeInfoCell'
import {EmployeeTwoStepsCell} from './EmployeeTwoStepsCell'
import {EmployeeActionsCell} from './EmployeeActionsCell'
import {EmployeeCustomHeader} from './EmployeeCustomHeader'
import {Employee} from '../../core/_models'

const EmployeeColumns: ReadonlyArray<Column<Employee>> = [
  {
    Header: (props) => (
      <EmployeeCustomHeader tableProps={props} title='S.No' className='min-w-125px' />
    ),
    accessor: 'serialNo',
  },
  {
    Header: (props) => (
      <EmployeeCustomHeader tableProps={props} title='Name' className='min-w-125px' />
    ),
    accessor: 'user.name',
  },
  {
    Header: (props) => (
      <EmployeeCustomHeader tableProps={props} title='Product' className='min-w-125px' />
    ),
    accessor: 'ProductMaster.name',
  },
  {
    Header: (props) => (
      <EmployeeCustomHeader tableProps={props} title='Category' className='min-w-125px' />
    ),
    accessor: 'buysell_category.name',
  },
  {
    Header: (props) => (
      <EmployeeCustomHeader tableProps={props} title='Location' className='min-w-125px' />
    ),
    accessor: 'pincode.region',
  },
  {
    Header: (props) => (
      <EmployeeCustomHeader tableProps={props} title='Status' className='min-w-125px' />
    ),
    accessor: 'status',
    Cell: ({...props}) => {
      // Status to Badge Color Mapping
      const statusMappings = {
        0: { label: "Deleted", className: "badge bg-danger" },   
        1: { label: "Requested", className: "badge bg-warning" }, 
        2: { label: "Completed", className: "badge bg-success" },  
        3: { label: "Expired", className: "badge bg-primary" }, 
        4: { label: "Closed", className: "badge bg-info" },
        // Add more mappings as needed
      };
      const statusValue = props.data[props.row.index].status
      const status = statusMappings[statusValue] || {label: 'Unknown', className: 'badge bg-light'}

      return <span className={status.className}>{status.label}</span>
    },
  },
  {
    Header: (props) => (
      <EmployeeCustomHeader tableProps={props} title='Start Date' className='min-w-125px' />
    ),
    accessor: 'created_date',
    Cell: ({...props}) => (
      <EmployeeInfoCell buy={props.data[props.row.index]} columnAccessor='Start Date' />
    ),
  },
  {
    Header: (props) => (
      <EmployeeCustomHeader tableProps={props} title='End Date' className='min-w-125px' />
    ),
    accessor: 'valid_date',
    Cell: ({...props}) => (
      <EmployeeInfoCell buy={props.data[props.row.index]} columnAccessor='End Date' />
    ),
  },
  {
    Header: (props) => (
      <EmployeeCustomHeader tableProps={props} title='Actions' className='text-end min-w-100px' />
    ),
    id: 'actions',
    Cell: ({...props}) => <EmployeeActionsCell buy={props.data[props.row.index]} />,
  },
]

export {EmployeeColumns}
