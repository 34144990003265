// @ts-nocheck
import {Column} from 'react-table'
import {
  EmployeeInfoCell,
  EmployeePlanStartCell,
  EmployeePlanEndCell,
  EmployeePlanRegCell,
  EmployeeSubscriptionInfoCell,
  EmployeePlanCell,
  InfoCell,
} from './EmployeeInfoCell'

import {EmployeeActionsCell} from './EmployeeActionsCell'
import {EmployeeCustomHeader} from './EmployeeCustomHeader'
import {Employee} from '../../core/_models'

const EmployeeColumns: ReadonlyArray<Column<Employee>> = [
  // {
  //   Header: (props) => (
  //     <EmployeeCustomHeader tableProps={props} title='S.No' className='min-w-125px' />
  //   ),
  //   accessor: 'serialNo',
  // },
  {
    Header: (props) => (
      <EmployeeCustomHeader tableProps={props} title='UID' className='min-w-75px' />
    ),
    accessor: 'primaryId',
  },
  {
    Header: (props) => (
      <EmployeeCustomHeader tableProps={props} title='User Name' className='min-w-125px' />
    ),
    accessor: 'name',
    Cell: ({...props}) => <InfoCell user={props.data[props.row.index]} />,
  },
  {
    Header: (props) => (
      <EmployeeCustomHeader tableProps={props} title='Register Date' className='min-w-125px' />
    ),
    accessor: 'created',
    Cell: ({...props}) => <EmployeePlanRegCell user={props.data[props.row.index]} />,
  },
  {
    Header: (props) => (
      <EmployeeCustomHeader tableProps={props} title='Mobile' className='min-w-125px' />
    ),
    accessor: 'mobile',
    Cell: ({ row }) => {
      const data = row.original;
      console.log("data", data);
      return (
        <div className="d-flex justify-content-start flex-column">
          <div>{data.mobile}</div>
          <div className="text-muted fw-semibold d-block fs-7">{data.email || ''}</div>
        </div>
      );
    },
  },
  {
    Header: (props) => (
      <EmployeeCustomHeader tableProps={props} title='Location' className='min-w-125px' />
    ),
    accessor: 'location',
    Cell: ({ row }) => {
      const data = row.original;
      return (
        <div className="d-flex justify-content-start flex-column">
          <div>{data.district}</div>
          <div className="text-muted fw-semibold d-block fs-7">{data.state || ''}</div>
        </div>
      );
    },
  },

  {
    Header: (props) => (
      <EmployeeCustomHeader tableProps={props} title='Plans' className='min-w-200px' />
    ),
    accessor: 'subscribedPlans',
    Cell: ({...props}) => <EmployeeSubscriptionInfoCell user={props.data[props.row.index]} />,
  },

  {
    Header: (props) => (
      <EmployeeCustomHeader tableProps={props} title='Status' className='min-w-125px' />
    ),
    accessor: 'status',
    Cell: ({...props}) => <EmployeeInfoCell user={props.data[props.row.index]} />,
  },
]

export {EmployeeColumns}
