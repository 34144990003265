import React, {useEffect, useMemo, useRef, useState} from 'react'
import {getPlan, reports} from './_requests'
import DatePicker from 'react-datepicker'
import Select, {Theme} from 'react-select'
import 'react-datepicker/dist/react-datepicker.css'
import './report.css'
import {useDownloadExcel} from 'react-export-table-to-excel'
import {useThemeMode} from '../../../../../../_metronic/partials/layout/theme-mode/ThemeModeProvider'
import * as XLSX from 'xlsx'
import {jsPDF} from 'jspdf'
import html2canvas from 'html2canvas'

export type PaginationState = {
  page: number
  items_per_page: 10 | 30 | 50 | 100
  total?: number
  links?: Array<{
    label: string
    active: boolean
    url: string | null
    page: number | null
  }>
}

type PageSize = 10 | 30 | 50 | 100

const ReportContent = () => {
  const tableRef = useRef<HTMLTableElement>(null)
  const [plan, setPlan] = useState<any>([])
  const [planid, setplanid] = useState('')
  const [statusid, setstatusid] = useState('')
  const [mobile, setmobile] = useState('')
  const [report, setReport] = useState<any>([])

  const [loading, setLoading] = useState(false)
  const [dateRange, setDateRange] = useState([null, null])
  const [startDate, endDate] = dateRange
  const [expiredDateRange, setExpiredDateRange] = useState([null, null])
  const [expiredStartDate, expiredEndDate] = expiredDateRange

  const [btnClearDisable, setbtnClearDisable] = useState(true)
  const [planstatus, setplanstatus] = useState<any>([])
  const {mode} = useThemeMode()
  const [alertMessage, setAlertMessage] = useState<string | null>(null)

  useEffect(() => {
    getPlan().then((res: any) => {
      const mappedOptions = res.data.Data.map((com: {id: any; name: any}) => ({
        value: com.id,
        label: com.name,
      }))

      const allOption = {value: 0, label: 'All Plans'}
      const optionsWithAll = [allOption, ...mappedOptions]
      setPlan(optionsWithAll)
    })

    const statusOption = [
      {value: '', label: 'All Plan Status'},
      {value: false, label: 'Active'},
      {value: true, label: 'Expired'},
    ]
    setplanstatus(statusOption)

    reports().then((res: any) => {
      setReport(res.data.Data)
      setbtnClearDisable(false)
    })
  }, [])

  var DatefileName = new Date()
  var fileName =
    DatefileName.getFullYear() + '-' + (DatefileName.getMonth() + 1) + '-' + DatefileName.getDate()

  const {onDownload} = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: `Plan_Report_${fileName}`,
    sheet: 'Users',
  })

  //dropdown
  if (mode === 'dark') {
    var customStyles = {
      control: (provided: any, state: any) => ({
        ...provided,
        backgroundColor: 'var(--kt-app-bg-color)',
        borderColor: '#393944',
        boxShadow: state.isFocused ? '0 0 0 1px #009ef7' : 'none',
        '&:hover': {
          color: 'red',
        },
      }),
      option: (provided: any, state: any) => ({
        ...provided,
        backgroundColor: state.isFocused ? '#009ef7' : 'var(--kt-app-bg-color)',
        color: state.isFocused ? 'white' : 'var(--kt-input-solid-color)',
      }),
      menu: (provided: any, state: any) => ({
        ...provided,
        backgroundColor: 'var(--kt-app-bg-color)',
        boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
        marginTop: 5,
        borderRadius: 5,
      }),
      placeholder: (provided: any, state: any) => ({
        ...provided,
        color: 'var(--kt-input-solid-color)',
      }),
      singleValue: (provided: any, state: any) => ({
        ...provided,
        color: state.isFocused ? 'var(--kt-input-solid-color)' : 'var(--kt-input-solid-color)',
      }),
      indicatorSeparator: (provided: any, state: any) => ({
        ...provided,
        backgroundColor: 'var(--kt-input-solid-color)',
      }),
    }
  } else {
    var customStyles = {
      control: (provided: any, state: any) => ({
        ...provided,
        backgroundColor: 'var(--kt-app-bg-color)',
        borderColor: '#c5c5c5',

        boxShadow: state.isFocused ? '0 0 0 1px #009ef7' : 'none',
      }),
      option: (provided: any, state: any) => ({
        ...provided,
        backgroundColor: state.isFocused ? '#009ef7' : 'var(--kt-app-bg-color)',
        color: state.isFocused ? 'white' : 'var(--kt-input-solid-color)',
      }),
      menu: (provided: any, state: any) => ({
        ...provided,
        backgroundColor: 'var(--kt-app-bg-color)',
        boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
        marginTop: 5,
        borderRadius: 5,
      }),
      placeholder: (provided: any, state: any) => ({
        ...provided,
        color: 'var(--kt-input-solid-color)',
      }),
      singleValue: (provided: any, state: any) => ({
        ...provided,
        color: state.isFocused ? 'var(--kt-input-solid-color)' : 'var(--kt-input-solid-color)',
      }),
      indicatorSeparator: (provided: any, state: any) => ({
        ...provided,
        backgroundColor: '#c5c5c5',
      }),
    }
  }

  const [selectedOption, setSelectedOption] = useState<any>({value: 0, label: 'All Plans'})
  const [reportData, setReportData] = useState<any>({
    plan: 'All Plan',
    planStatus: 'All Plan Status',
    mobileNumber: '',
    subscriptionDate: '',
    expiredDate: '',
  })
  const [planselectedOption, setplanselectedOption] = useState<any>({
    value: '',
    label: 'All Plan Status',
  })

  const handleSelectChange = (selectedOption: any) => {
    setReportData((prevState: any) => ({
      ...prevState,
      plan: selectedOption?.label,
    }))
    setSelectedOption(selectedOption)
    var val = selectedOption?.value
    setplanid(val)
  }

  const planhandleSelectChange = (planselectedOption: any) => {
    setReportData((prevState: any) => ({
      ...prevState,
      planStatus: planselectedOption?.label,
    }))
    setplanselectedOption(planselectedOption)
    var val = planselectedOption?.value

    if (val === 0) {
      var statusVal = typeof String(val)
      setstatusid(statusVal)
    } else {
      setstatusid(val)
    }
  }

  const companyReport = () => {
    let createdDate = (document.getElementById('Daterange') as HTMLInputElement).value
    let expiredDate = (document.getElementById('DaterangeExpired') as HTMLInputElement).value
    setAlertMessage('')
    const data = {
      planId: planid,
      isExpired: statusid,
      mobile: mobile,
      createdDate,
      expiredDate,
    }

    reports(data).then((res: any) => {
      setReport(res.data.Data)
      setbtnClearDisable(false)
      setCurrentPage(1)
    })
  }

  const clearReport = () => {
    setSelectedOption({value: 0, label: 'All Plans'})
    setplanselectedOption({
      value: '',
      label: 'All Plan Status',
    })
    setReportData({
      plan: 'All Plan',
      planStatus: 'All Plan Status',
      mobileNumber: '',
      subscriptionDate: '',
      expiredDate: '',
    })
    setplanid('')
    setstatusid('')
    setExpiredDateRange([null, null])
    setDateRange([null, null])
    setmobile('')
    reports().then((res: any) => {
      setReport(res.data.Data)
      setbtnClearDisable(false)
    })
  }
  const [currentPage, setCurrentPage] = useState<any>(1)
  const [itemsPerPage, setItemsPerPage] = useState<PageSize>(10)

  const indexOfLastItem = currentPage * itemsPerPage
  const indexOfFirstItem = indexOfLastItem - itemsPerPage
  const currentItems = report.slice(indexOfFirstItem, indexOfLastItem)
  const pages = Math.ceil(report.length / itemsPerPage)

  const pageNumbers = []
  for (let i = 1; i <= pages; i++) {
    pageNumbers.push(i)
  }
  const renderPageNumbers = pageNumbers.map((number) => {
    const isActive = number === currentPage

    let pageNumberContent

    if (
      number === 1 ||
      number === pages ||
      (number >= currentPage - 1 && number <= currentPage + 1)
    ) {
      pageNumberContent = (
        <li key={number} className={`page-item ${number === currentPage ? 'active' : ''}`}>
          <a
            className={`page-link cursorPointer ${number === currentPage ? 'active' : ''}`}
            onClick={() => setCurrentPage(number)}
          >
            {number}
          </a>
        </li>
      )
    } else if (number === 2 || number === pages - 1) {
      // Add links for second and second-to-last pages
      pageNumberContent = (
        <li key={number} className='page-item'>
          <a className='page-link cursorPointer' onClick={() => setCurrentPage(number)}>
            {number}
          </a>
        </li>
      )
    } else if (number === 3 && currentPage > 4) {
      // Add ellipsis before the link to the second page
      pageNumberContent = (
        <li key={number} className='page-item'>
          <span className='ellipsis'>...</span>
        </li>
      )
    } else if (number === pages - 2 && currentPage < pages - 3) {
      // Add ellipsis before the link to the second-to-last page
      pageNumberContent = (
        <li key={number} className='page-item'>
          <span className='ellipsis'>...</span>
        </li>
      )
    }

    return pageNumberContent
  })

  const nextPage = () => {
    if (currentPage < pages) {
      setCurrentPage(currentPage + 1)
    }
  }

  const prevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1)
    }
  }

  const handleItemsPerPageChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const newItemsPerPage = Number(event.target.value) as PageSize
    setItemsPerPage(newItemsPerPage)
    setCurrentPage(1) // Reset to first page on items per page change
  }

  const exportToPDF = () => {
    const input = tableRef.current

    // Check if the reference is valid
    if (input) {
      html2canvas(input).then((canvas) => {
        const imgData = canvas.toDataURL('image/png')

        // Create a new jsPDF instance with 'portrait' orientation and A4 size
        const pdf = new jsPDF({
          orientation: 'portrait',
          unit: 'mm',
          format: 'a4',
        })

        // Get the A4 page dimensions in mm (210x297)
        const pdfWidth = 210
        const pdfHeight = 297

        // Get the canvas image width and height
        const imgWidth = canvas.width
        const imgHeight = canvas.height

        // Scale the image to fit the width of the PDF while maintaining aspect ratio
        const ratio = imgWidth / imgHeight
        const imgScaledWidth = pdfWidth - 20 // Subtract padding (10 mm on each side)
        const imgScaledHeight = imgScaledWidth / ratio

        // Calculate padding
        const xOffset = 10 // Left padding
        const yOffset = 10 // Top padding

        // Add the image to the PDF with calculated dimensions
        pdf.addImage(imgData, 'PNG', xOffset, yOffset, imgScaledWidth, imgScaledHeight)

        // Save the PDF
        pdf.save('users_data.pdf')
      })
    } else {
      console.error('Table element not found!')
    }
  }

  const handlePrint = () => {
    if (tableRef.current) {
      const printContent = tableRef.current.innerHTML

      // Create a new window for printing
      const printWindow = window.open('', '_blank')
      if (printWindow) {
        printWindow.document.write(`
          <html>
            <head>
              <title>Print</title>
              <style>
                /* Add styles for printing here */
                body {
                  margin: 0;
                  padding: 20px;
                  font-family: Arial, sans-serif;
                }
                table {
                  width: 100%;
                  border-collapse: collapse;
                }
                        h1 {
                text-align: center; /* Center the header */
                margin-bottom: 20px; /* Space below the header */
              }
                th, td {
                  border: 1px solid #000;
                  padding: 8px;
                  text-align: left;
                }
                th {
                  background-color: #f2f2f2; /* Light gray background for headers */
                }
                tr:nth-child(even) {
                  background-color: #f9f9f9; /* Alternate row color */
                }
                tr:hover {
                  background-color: #e0e0e0; /* Row hover effect */
                }
              </style>
            </head>
            <body>
             <h1>User Plan Details</h1>
              ${printContent}
            </body>
          </html>
        `)

        printWindow.document.close() // Close the document to ensure styles are applied
        printWindow.focus() // Focus on the new window
        printWindow.print() // Trigger print
      }
    }
  }

  const handleExport = (format: string) => {
    console.log('format', format, format === 'print')
    if (format === 'pdf') {
      exportToPDF()
    }
    if (format === 'excel') {
      onDownload()
    }
    if (format === 'print') {
      handlePrint()
    }
  }

  return (
    <>
      <div className='card '>
        {/* <form onSubmit={formik.handleSubmit} noValidate className='form'> */}

        <div className='card-header d-flex justify-content-between align-items-center'>
          <h5>User Plan Status</h5>
          <div className='dropdown col-2'>
            <button
              className='btn btn-primary dropdown-toggle w-100'
              type='button'
              id='exportDropdown'
              data-bs-toggle='dropdown'
              aria-expanded='false'
            >
              Export
            </button>
            <ul className='dropdown-menu w-100' aria-labelledby='exportDropdown'>
              <li>
                <button
                  className='btn-hover-success dropdown-item p-2'
                  onClick={() => handleExport('pdf')}
                >
                  <i className='fas fa-file-pdf me-2' style={{fontSize: '1.2rem'}}></i> PDF
                </button>
              </li>
              <li>
                <button
                  className='btn-hover-success dropdown-item p-2'
                  onClick={() => {
                    handleExport('excel')
                    console.log('Excel clicked')
                  }}
                >
                  <i className='fas fa-file-excel me-2' style={{fontSize: '1.2rem'}}></i> Excel
                </button>
              </li>
              <li>
                <button
                  className='btn-hover-success dropdown-item p-2'
                  onClick={() => handleExport('print')}
                >
                  <i className='fas fa-print me-2' style={{fontSize: '1.2rem'}}></i> Print
                </button>
              </li>
            </ul>
          </div>
        </div>

        <div className='card-body py-3'>
          {/* begin::Table container */}
          <div className='row mb-10'>
            <div className='col-lg-3'>
              <label className=' col-form-label  fw-bold fs-6'>Plan</label>
              <Select
                className='my-react-select-container'
                classNamePrefix='my-react-select'
                value={selectedOption}
                onChange={handleSelectChange}
                options={plan}
                isSearchable
                // onInputChange={handleInputChange}
                styles={customStyles}
              />
            </div>
            <div className='col-lg-3'>
              <label className=' col-form-label    fw-bold fs-6'>Plan Status</label>
              <Select
                className='my-react-select-container'
                classNamePrefix='my-react-select'
                value={planselectedOption}
                onChange={planhandleSelectChange}
                options={planstatus}
                isSearchable
                // onInputChange={handleInputChange}
                styles={customStyles}
              />
            </div>
            <div className='col-lg-3'>
              <label className=' col-form-label  fw-bold fs-6'>Mobile Number</label>
              <input
                type='text'
                placeholder='Search Mobile'
                value={mobile}
                onChange={(e) => {
                  setmobile(e.target.value)
                  setReportData((prevState: any) => ({
                    ...prevState,
                    mobileNumber: e.target.value,
                  }))
                }}
                className='form-control form-control-lg form-control-solid'
              />
            </div>

            <div className='col-lg-3'>
              <label className=' col-form-label fw-bold fs-6'>Subscription Date</label>
              <DatePicker
                className='form-control form-control-lg form-control-solid'
                id='Daterange'
                selectsRange={true}
                startDate={startDate}
                endDate={endDate}
                onChange={(update: any, e: any) => {
                  setDateRange(update)
                  e.stopPropagation()
                  const formattedSubscriptionDate = update
                    ? update
                        .map((date: Date) =>
                          date.toLocaleDateString('en-GB', {
                            day: 'numeric',
                            month: 'short',
                            year: 'numeric',
                          })
                        )
                        .join(' - ')
                    : ''

                  setReportData((prevState: any) => ({
                    ...prevState,
                    subscriptionDate: formattedSubscriptionDate,
                  }))
                }}
                placeholderText='Subscription Date'
                isClearable={true}
                dateFormat='dd/MM/yyyy'
              />
              {alertMessage && <div className='text-danger'>{alertMessage}</div>}
            </div>

            <div className='col-lg-12 row' style={{float: 'right', paddingRight: '0px'}}>
              <div className='col-lg-3'>
                <label className=' col-form-label fw-bold fs-6'>Expired Date</label>
                <DatePicker
                  className='form-control form-control-lg form-control-solid'
                  id='DaterangeExpired'
                  selectsRange={true}
                  startDate={expiredStartDate}
                  endDate={expiredEndDate}
                  onChange={(update: any, e: any) => {
                    setExpiredDateRange(update)
                    e.stopPropagation()
                    const formattedExpiredDate = update
                      ? update
                          .map((date: Date) =>
                            date.toLocaleDateString('en-GB', {
                              day: 'numeric',
                              month: 'short',
                              year: 'numeric',
                            })
                          )
                          .join(' - ')
                      : '' // Formatting range as 'dd MMM yyyy'

                    setReportData((prevState: any) => ({
                      ...prevState,
                      expiredDate: formattedExpiredDate,
                    }))
                  }}
                  isClearable={true}
                  placeholderText='Expired Date'
                  dateFormat='dd/MM/yyyy'
                />
                {/* {alertMessage && <div className='text-danger'>{alertMessage}</div>} */}
              </div>
              <div className='col-lg-3 pt-12'>
                <button onClick={companyReport} className='btn btn-primary col-lg-12' type='submit'>
                  Search
                </button>
              </div>
              <div className='col-lg-3 pt-12'>
                <button
                  onClick={clearReport}
                  className='btn btn-primary col-lg-12'
                  disabled={btnClearDisable}
                >
                  Clear
                </button>
              </div>
            </div>
          </div>

          <div className='table-responsive'>
            {/* begin::Table */}
            <div ref={tableRef}>
              <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                {/* begin::Table head */}
                <thead>
                  <tr className='fw-bold text-muted' style={{borderBottom: 'none'}}>
                    <th className='min-w-50px' colSpan={5}>
                      {' '}
                      Plan : {reportData.plan}
                    </th>
                    <th className='min-w-120px' colSpan={5}>
                      {' '}
                      Plan Status : {reportData.planStatus}
                    </th>
                  </tr>
                  <tr className='fw-bold text-muted' style={{borderBottom: 'none'}}>
                    <th className='min-w-50px' colSpan={5}>
                      {' '}
                      Subscription Date : {reportData.subscriptionDate}
                    </th>
                    <th className='min-w-120px' colSpan={5}>
                      {' '}
                      Expired Date : {reportData.expiredDate}
                    </th>
                  </tr>
                  <tr className='fw-bold text-muted' style={{borderBottom: 'none'}}>
                    <th className='min-w-50px' colSpan={5}>
                      {' '}
                      Mobile Number : {reportData.mobileNumber}
                    </th>
                  </tr>
                  <tr className='fw-bold text-muted'>
                    <th className='min-w-50px'>S.No.</th>
                    <th className='min-w-50px'>UID</th>
                    <th className='min-w-140px'>USER NAME</th>
                    <th className='min-w-140px'>MOBILE</th>
                    <th className='min-w-120px'>PLAN</th>
                    <th className='min-w-100px'>PLAN START</th>
                    <th className='min-w-100px'>PLAN END</th>
                    <th className='min-w-100px'>PLAN STATUS</th>
                    <th className='min-w-140px'>SUBSCRIPTION DATE</th>
                  </tr>
                </thead>
                {loading && (
                  <tbody>
                    <tr>
                      <td colSpan={9} style={{textAlign: 'center'}}>
                        Please wait...
                      </td>
                    </tr>
                  </tbody>
                )}
                {!loading && (
                  <tbody>
                    {currentItems.length > 0 ? (
                      currentItems.map((item: any, i: any) => (
                        <tr key={i}>
                          <td> {i + 1 + (currentPage - 1) * itemsPerPage}</td>
                          <td>{item?.userId}</td>
                          <td>{item?.user?.name}</td>
                          <td>{item?.user?.mobile}</td>
                          <td>{item?.plan?.name}</td>
                          <td>
                            {new Date(item.startDate).toLocaleDateString('en-GB', {
                              day: 'numeric',
                              month: 'short',
                              year: 'numeric',
                            })}
                          </td>
                          <td>
                            {new Date(item.endDate).toLocaleDateString('en-GB', {
                              day: 'numeric',
                              month: 'short',
                              year: 'numeric',
                            })}
                          </td>
                          <td>
                            {item.isExpired ? (
                              <span className='badge badge-danger'>Expired</span>
                            ) : (
                              <span className='badge badge-success'>Active</span>
                            )}
                          </td>
                          <td>
                            {new Date(item.createdAt).toLocaleDateString('en-GB', {
                              day: 'numeric',
                              month: 'short',
                              year: 'numeric',
                            })}
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan={9}>
                          <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                            <h6 className='text-muted fw-bold'>No matching records found</h6>
                          </div>
                        </td>
                      </tr>
                    )}
                  </tbody>
                )}
                {/* end::Table body */}
              </table>
            </div>
            {/* pagination starts*/}
            {currentItems.length > 0 && (
              <>
                <nav className='pagination-wrap mt-4'>
                  <div className='d-flex justify-content-between mt-4'>
                    <div className='col-lg-5 col-12'>
                      <p>
                        Showing {indexOfFirstItem + 1} to {Math.min(indexOfLastItem, report.length)}{' '}
                        of {report.length} results
                      </p>
                    </div>

                    <div className='col-lg-7 col-12'>
                      <ul className='pagination mb-0'>
                        <li className='page-item'>
                          <a className='page-link cursorPointer' onClick={() => setCurrentPage(1)}>
                            <p className='material-symbols-outlined mt-4'> First </p>
                          </a>
                        </li>

                        <li className='page-item'>
                          <a
                            className='page-link cursorPointer'
                            onClick={prevPage}
                            aria-label='Previous'
                          >
                            <p className='material-symbols-outlined mt-4'>Before</p>
                          </a>
                        </li>
                        {renderPageNumbers}
                        <li className='page-item'>
                          <a
                            className='page-link cursorPointer'
                            onClick={nextPage}
                            aria-label='Next'
                          >
                            <p className='material-symbols-outlined mt-4'>Next</p>
                          </a>
                        </li>
                        <li className='page-item'>
                          <a
                            className='page-link cursorPointer'
                            onClick={() => setCurrentPage(pages)}
                          >
                            <p className='material-symbols-outlined mt-4'>Last</p>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </nav>
                <div className='row d-flex'>
                  <div className='col-3'>
                    <label className='form-label me-4'>Items per page</label>
                  </div>
                  <div className='col-2'>
                    <select
                      className='form-select'
                      value={itemsPerPage}
                      onChange={handleItemsPerPageChange}
                    >
                      {[10, 30, 50, 100].map((size) => (
                        <option key={size} value={size}>
                          {size}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </>
            )}
          </div>
          {/* end::Table container */}
        </div>
        {/* begin::Body */}
        {/* </form> */}
      </div>
    </>
  )
}

export {ReportContent}
