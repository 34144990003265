/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {News} from '../../core/_models'
import Avatar from 'react-avatar'
import {toAbsoluteUrl} from '../../../../../../../_metronic/helpers'
import '../../../user.css'
import {dateFormat, timeFormat} from '../../../../../auth/core/AuthHelpers'
import {Link} from 'react-router-dom'

type Props = {
  user: any
}

const EmployeeInfoCell: FC<Props> = ({user}) => (
  <div className='d-flex align-items-center'>
    <h6 className={`status-${user?.status}`}>{user?.status}</h6>
  </div>
)

const EmployeePlanStartCell: FC<Props> = ({user}) => (
  <div className='d-flex align-items-center'>
    {user?.plan_start ? dateFormat(user?.plan_start) : ''}
  </div>
)
const EmployeePlanEndCell: FC<Props> = ({user}) => (
  <div className='d-flex align-items-center'>
    {user?.plan_end ? dateFormat(user?.plan_end) : ''}
  </div>
)
const EmployeePlanRegCell: FC<Props> = ({user}) => (
  <div className='d-flex justify-content-start flex-column'>
    <div>{dateFormat(user?.created)}</div>
    <div className='text-muted fw-semibold text-muted d-block fs-7'>
      {timeFormat(user?.created)}
    </div>
  </div>
)
const EmployeePlanCell: FC<Props> = ({user}) => (
  <div className='d-flex align-items-center'>
    {user?.plan_type !== 0 ? user?.plan?.name : 'No plan '}
  </div>
)
const planColors: {[key: string]: string} = {
  Seller: 'badge-warning', // Yellow for Seller
  Buyer: 'badge-primary', // Blue for Buyer
  Market: 'badge-success', // Green for Market
  Trader: 'badge-info', // Light blue for Trader
  Free: 'badge-light', // Light badge for Free
  Subscribed: 'badge-dark', // Dark badge for Subscribed
}

const EmployeeSubscriptionInfoCell: FC<Props> = ({user}) => {
  // Function to return the appropriate Bootstrap badge class based on plan keyword match
  const getPlanBadgeClass = (plan: string) => {
    for (const keyword in planColors) {
      if (plan.includes(keyword)) {
        return planColors[keyword] // Return the matched badge class
      }
    }
    return 'badge-secondary' // Default gray badge for unknown plans
  }

  return (
    <div className='d-flex flex-wrap align-items-center'>
      {user?.subscribedPlans?.map((plan: any, index: any) => (
        <span
          key={index}
          className={`badge p-2 m-1  fs-0.5 rounded ${getPlanBadgeClass(plan)} d-flex align-items-center`}
        >
          {plan}
        </span>
      )) || 'No Active plan'}
    </div>
  )
}

const InfoCell: FC<Props> = ({user}) => {
  const badgeBorderClasses: any = {
    Trader: 'text-info',
    Buyer: 'text-success',
    Seller: 'text-warning',
    Farmer: 'text-primary',
  }

  return (
    <div className='d-flex align-items-center'>
      <div className='symbol symbol-45px me-2'>
      <Link
          to={`/users/view/${user?.primaryId}`}
          className='text-gray-900 fw-bold text-hover-primary fs-6'
        >
        {user?.profile_img ? (
          <img
            src={user.profile_img}
            alt='Profile'
            className='rounded-circle' // Apply the rounded class for circular shape
            style={{width: '45px', height: '45px', objectFit: 'cover'}}
          />
        ) : (
          <Avatar
            size='50'
            round='25px'
            className='symbol symbol-45px me-2'
            style={{fontSize: '18px'}}
            maxInitials={2}
            name={user?.name}
          />
        )}</Link>
      </div>
      <div className='d-flex justify-content-start flex-column'>
        <Link
          to={`/users/view/${user?.primaryId}`}
          className='text-gray-900 fw-bold text-hover-primary fs-6'
        >
          {user?.name}
        </Link>
        {user?.userType?.name && (
          <span
            className={`fw-semibold d-inline-block fs-7  ${
              badgeBorderClasses[user.userType.name] || 'border border-secondary text-secondary'
            } text-truncate`}
          >
            {user.userType.name}
          </span>
        )}
      </div>
    </div>
  )
}

export {
  EmployeeInfoCell,
  EmployeePlanStartCell,
  EmployeePlanEndCell,
  EmployeePlanRegCell,
  EmployeePlanCell,
  EmployeeSubscriptionInfoCell,
  InfoCell,
}
