/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect, useState} from 'react'
import {Link, useLocation, useParams} from 'react-router-dom'
import {KTSVG} from '../../../../../_metronic/helpers'

import Avatar from 'react-avatar'
import {viewData} from '../user-list/core/_requests'
import {dateFormat} from '../../../auth'
import {log} from 'console'

export function UserHeader() {
  const location = useLocation()
  const {id} = useParams()

  const [data, setData] = useState<any>([])
  useEffect(() => {
    viewData(id).then((res: any) => {
      console.log('res.Data111', res.data[0])
      setData(res.data[0])
    })
  }, [])

  var view = '/users/view/' + id
  var userPlan = '/users/plan/' + id
  var subscribe = '/users/subscription/' + id
  // var upgrade = '/users/upgrade/' + id
  console.log('user-data', data)
  return (
    <>
      <div className='card mb-5 mb-xl-10'>
        <div className='card-body pt-9 pb-0'>
          <div className='d-flex flex-wrap flex-sm-nowrap mb-3'>
            <div className='me-7 mb-4'>
              <div className='symbol symbol-100px symbol-lg-160px symbol-fixed position-relative'>
                {data?.profile_img ? (
                  <img
                    src={data.profile_img}
                    alt='Profile'
                    className='rounded-circle' // Apply the rounded class for circular shape
                    style={{width: '100px', height: '100px', objectFit: 'cover'}}
                  />
                ) : (
                  <Avatar
                    name={data?.name}
                    maxInitials={2}
                    round={true} // Ensure the Avatar is round
                    color='#607458'
                    fgColor='#1b1b29'
                    size='100' // Matching the size of the image
                  />
                )}
              </div>
            </div>

            <div className='flex-grow-1'>
              <div className='d-flex justify-content-between align-items-start flex-wrap mb-2'>
                <div className='row m-0 w-100'>
                  <div className='col mb-2'>
                    <a className='text-gray-800 text-hover-primary fs-2 fw-bolder me-1'>
                      {data?.name} - {data?.user_type}
                    </a>
                  </div>

                  <div className='col-auto'>
                    <span
                      className={`status-${data?.status} fw-bolder ms-2 py-1 px-3`}
                      style={{fontSize: '12px'}}
                    >
                      {data?.status}
                    </span>
                  </div>
                </div>
              </div>
              {/* <div className='d-flex flex-wrap flex-stack'>
                <div className='d-flex flex-column flex-grow-1 pe-8'>
                  <div className='d-flex flex-wrap'>
                    <div className='border border-gray-300 border-dashed rounded min-w-125px  py-3 px-4 me-7 mb-3'>
                      <div className='fw-bold text-gray-400 mb-5'>Mobile Number</div>
                      <div className='d-flex'>
                        <KTSVG
                          path='/media/icons/duotune/general/telephone.svg'
                          className='svg-icon-3 svg-icon-success me-2'
                        />
                        <div className='fs-5 fw-bolder'>{data?.mobile}</div>
                      </div>
                    </div>
                    {data?.email && (
                      <div className='border border-gray-300 border-dashed rounded min-w-125px  py-3 px-4 me-7 mb-3'>
                        <div className='fw-bold text-gray-400 mb-5'>Email</div>
                        <div className='d-flex'>
                          <KTSVG
                            path='/media/icons/duotune/general/envelope-at.svg'
                            className='svg-icon-3 svg-icon-success me-2'
                          />
                          <div className='fs-6 text-gray-800 fw-bolder'>{data?.email}</div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div className='d-flex flex-column'>
                  <a
                    className={`status-${data?.status} fw-bolder ms-2   py-1 px-3`}
                    style={{fontSize: '12px'}}
                  >
                    {data?.status}
                  </a>
                </div>
              </div> */}
              <div className='d-flex flex-wrap justify-content-start'>
                <div className='d-flex flex-column flex-grow-1 pe-8 mb-3'>
                  {/* Row for Mobile and Email */}
                  <div className='d-flex flex-wrap w-100 mb-3'>
                    {/* Mobile */}
                    <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-4 mb-3'>
                      <div className='d-flex align-items-center'>
                        <i className='fas fa-phone text-success me-2'></i>
                        <div className='fs-5 fw-bolder'>{data?.mobile}</div>
                      </div>
                    </div>

                    {/* Email */}
                    {data?.email && (
                      <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-4 mb-3'>
                        <div className='d-flex align-items-center'>
                          <i className='fas fa-envelope text-success me-2'></i>
                          <div className='fs-6 text-gray-800 fw-bolder'>{data?.email}</div>
                        </div>
                      </div>
                    )}
                  </div>

                  {/* Row for District and State */}
                  <div className='d-flex flex-wrap w-100'>
                    {/* District */}
                    {data?.district && (
                      <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-4 mb-3'>
                        <div className='d-flex align-items-center'>
                          <i className='fas fa-map-marker-alt text-success me-2'></i>
                          <div className='fs-6 text-gray-800 fw-bolder'>{data.district} , {data.state}</div>
                        </div>
                      </div>
                    )}


                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className='d-flex overflow-auto h-55px'>
            <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap'>
              <li className='nav-item'>
                <Link
                  className={
                    `nav-link text-active-primary me-6 ` + (location.pathname === view && 'active')
                  }
                  to={view}
                >
                  Overview
                </Link>
              </li>

              <li className='nav-item'>
                <Link
                  className={
                    `nav-link text-active-primary me-6 ` +
                    (location.pathname === userPlan && 'active')
                  }
                  to={userPlan}
                >
                  Plans
                </Link>
              </li>
              <li className='nav-item'>
                <Link
                  className={
                    `nav-link text-active-primary me-6 ` +
                    (location.pathname === subscribe && 'active')
                  }
                  to={subscribe}
                >
                  Subscriptions
                </Link>
              </li>
              {/* <li className='nav-item'>
                <Link
                  className={
                    `nav-link text-active-primary me-6 ` +
                    (location.pathname === upgrade && 'active')
                  }
                  to={upgrade}
                >
                  Plan Activate
                </Link>
              </li> */}
            </ul>
          </div>
        </div>
      </div>
    </>
  )
}
