import {Route, Routes, Outlet, Navigate, useNavigate} from 'react-router-dom'
import {PageLink, PageTitle} from '../../../../_metronic/layout/core'
import {EmployeeListWrapper} from './user-list/EmployeeList'

// import {View} from './user-form/View'
import {UserHeader} from './user-form/UserHeader'
import {ViewUser} from './user-form/ViewUser'
import {ListWrapper} from './subscription/list/EmployeeList'
import {UpgradeListWrapper} from './upgrade/list/PlanList'
import { UserPlan } from './user-form/userplan'

const employeesBreadcrumbs: Array<PageLink> = [
  {
    title: 'User Management',
    path: '/users',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const BuyPage = () => {
  const navigate = useNavigate()
  function goBack() {
    navigate(-1)
  }
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path='/list'
          element={
            <>
              <PageTitle breadcrumbs={employeesBreadcrumbs}>Users list</PageTitle>
              <EmployeeListWrapper />
            </>
          }
        />
      </Route>
      <Route index element={<Navigate to='list' />} />

      <Route
        path='/view/:id'
        element={
          <>
            <PageTitle breadcrumbs={employeesBreadcrumbs}>User Overview</PageTitle>
            <button onClick={goBack} style={{marginBottom:"15px"}}  className='btn btn-sm btn-info px-6 align-self-center text-nowrap'> 
           <i className="bi bi-reply fs-4 me-2"></i> Go Back </button> 
            <UserHeader />
            <ViewUser />
          </>
        }
      />

      <Route
        path='/subscription/:id'
        element={
          <>
            <PageTitle breadcrumbs={employeesBreadcrumbs}>Subscription Details</PageTitle>
            <button
              onClick={goBack}
              style={{marginBottom: '15px'}}
              className='btn btn-sm btn-info px-6 align-self-center text-nowrap'
            >
              <i className='bi bi-reply fs-4 me-2'></i> Go Back{' '}
            </button>
            <UserHeader />
            <ListWrapper />
          </>
        }
      />
      <Route
        path='/plan/:id'
        element={
          <>
            <PageTitle breadcrumbs={employeesBreadcrumbs}>User Plan Details</PageTitle>
            <button
              onClick={goBack}
              style={{marginBottom: '15px'}}
              className='btn btn-sm btn-info px-6 align-self-center text-nowrap'
            >
              <i className='bi bi-reply fs-4 me-2'></i> Go Back{' '}
            </button>
            <UserHeader />
            <UserPlan />
          </>
        }
      />
      <Route
        path='/upgrade/:id'
        element={
          <>
            <PageTitle breadcrumbs={employeesBreadcrumbs}>Plan Upgrade</PageTitle>
            <button
              onClick={goBack}
              style={{marginBottom: '15px'}}
              className='btn btn-sm btn-info px-6 align-self-center text-nowrap'
            >
              <i className='bi bi-reply fs-4 me-2'></i> Go Back{' '}
            </button>
            {/* <UserHeader /> */}
            <UpgradeListWrapper />
          </>
        }
      />
    </Routes>
  )
}

export default BuyPage
